.skeleton-shimmer {
    overflow: hidden;
    position: relative;
}

.skeleton-shimmer::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c5c5c5;
    background-image: linear-gradient(to right, #c5c5c5 0%, #d6d6d6 20%, #c5c5c5 40%, #c5c5c5 100%);
    background-size: 800px 500px; 
    background-repeat: no-repeat;
    animation: shimmer 1s linear infinite;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    } 100% {
        background-position: 468px 0; 
    }
}

.skeleton-base {
    /* background-color: rgba(0, 0, 0, 0.4); */
    border-radius: 7px;
}

.skeleton-title {
    width: 10em;
    height: 1.2em;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    margin-bottom: 1em;
}

.skeleton-small-text {
    height: 1em;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    width: 25%;
    margin-bottom: 0.35em;
}

.skeleton-medium-text {
    height: 1em;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    width: 50%;
    margin-bottom: 0.35em;
}

.skeleton-large-text {
    height: 1em;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    width: 100%;
    margin-bottom: 0.35em;
}

.skeleton-small-button {
    height: 2em;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    width: 5.125em;
}

.skeleton-medium-button {
    height: 2em;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    width: 10em;
}

.skeleton-small-dot {
    width: 1em;
    height: 1em;
    border-radius: 100%;
}

.skeleton-medium-dot {
    width: 1.5em;
    height: 1.5em;
    margin: 3px;
    border-radius: 100%;
}
